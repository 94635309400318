import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { getIconsByType, ICON_TYPES } from '../../utils/icons';

const PinterestIcon = getIconsByType(ICON_TYPES.PINTEREST);
const InstagramIcon = getIconsByType(ICON_TYPES.INSTAGRAM);
const TwitterIcon = getIconsByType(ICON_TYPES.TWITTER);

export const StyledPinterestIcon = styled(PinterestIcon)({
  fontSize: 22,
  marginTop: -1,
  '@media (max-width: 480px)': {
    fontSize: 16,
    marginTop: 0,
    marginBottom: -1,
  },
},
({ theme }) => ({
  color: theme.colors.socialLink,
  '@media (max-width: 480px)': {
    color: theme.colors.textGray,
  },
}));

export const StyledInstagramIcon = styled(InstagramIcon)({
  fontSize: 20,
  padding: 2,
  borderRadius: '50%',
  '@media (max-width: 480px)': {
    fontSize: 14,
  },
},
({ theme }) => ({
  color: theme.colors.background,
  backgroundColor: theme.colors.socialLink,
  '@media (max-width: 480px)': {
    backgroundColor: theme.colors.textGray,
  },
}));

export const StyledTwitterIcon = styled(TwitterIcon)({
  fontSize: 20,
  padding: 3,
  borderRadius: '50%',
  '@media (max-width: 480px)': {
    fontSize: 14,
  },
},
({ theme }) => ({
  color: theme.colors.background,
  backgroundColor: theme.colors.socialLink,
  '@media (max-width: 480px)': {
    backgroundColor: theme.colors.textGray,
  },
}));

export const StyledFacebookIcon = styled.div({
  width: 20,
  height: 20,
  borderRadius: '50%',
  position: 'relative',
  '&:before': {
    content: '"f"',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: '700',
    lineHeight: '20px',
  },
  '@media (max-width: 480px)': {
    width: 14,
    height: 14,
    '&:before': {
      fontSize: 12,
      lineHeight: '14px',
    },
  },
},
({ theme }) => ({
  backgroundColor: theme.colors.socialLink,
  '&:before': {
    color: theme.colors.background,
  },
  '@media (max-width: 480px)': {
    backgroundColor: theme.colors.textGray,
  },
}));

export const StyledFooter = styled.footer({
  width: '100%',
},
({ theme }) => ({
  background: theme.colors.background,
}));

export const Wrapper = styled.div({
  maxWidth: 1920,
  minWidth: 320,
  margin: '0 auto',
  padding: '65px 10% 0 10%',
  display: 'flex',
  '@media (max-width: 480px)': {
    padding: '31px 0 18px 0',
    flexDirection: 'column',
    alignItems: 'center',
    '&:before': {
      display: 'block',
      content: '""',
      width: 122,
      height: 5,
      marginBottom: 15,
      flex: '1 0 5px',
    },
  },
},
({ theme }) => ({
  height: theme.sizes.footerHeight,
  '@media (max-width: 480px)': {
    height: 'auto',
    '&:before': {
      backgroundColor: theme.colors.textHighlighted,
    },
  },
}));

export const LogoWrapper = styled.div({
  '@media (max-width: 480px)': {
    display: 'none',
  },
});

export const MenuHeader = styled.h5({
  marginBottom: 30,
  textTransform: 'uppercase',
  fontSize: 15,
  '@media (max-width: 480px)': {
    display: 'none',
  },
},
({ theme }) => ({
  color: theme.colors.textGray,
}));

export const MenuItem = styled.li({
  listStyle: 'none',
  marginRight: 10,
  marginBottom: 10,
  '@media (max-width: 480px)': {
    margin: '0 7px',
  },
});

export const MenuItemSocialLinks = styled(MenuItem)({
  display: 'none',
  '@media (max-width: 480px)': {
    display: 'block',
  },
});

export const Menu = styled.div({
  width: 200,
  '@media (max-width: 480px)': {
    width: 'auto',
    '& ul': {
      display: 'flex',
    },
  },
});

export const InfoContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
},
({ theme }) => ({
  height: theme.sizes.footerInfoHeight,
  background: theme.colors.footerBackground,
  color: theme.colors.textReversed,
  '@media (max-width: 480px)': {
    height: theme.sizes.footerMobileInfoHeight,
  },
}));

export const StyledImg = styled.img({
  width: 195,
  marginRight: 187,
  marginBottom: 18,
  cursor: 'pointer',
});

export const SocialLinksContainer = styled.div({
  display: 'flex',
  paddingLeft: 10,
  '@media (max-width: 480px)': {
    alignItems: 'baseline',
    marginTop: 3,
    paddingLeft: 0,
  },
});

export const StyledLink = styled(Link)({
  fontSize: 18,
  '&:hover': {
    fontWeight: '600',
  },
  '@media (max-width: 480px)': {
    fontSize: 12,
  },
},
({ theme }) => ({
  color: theme.colors.textHighlighted,
  '@media (max-width: 480px)': {
    color: theme.colors.textGray,
  },
}));

export const StyledInfoLink = styled(Link)({
  fontSize: 18,
  '@media (max-width: 480px)': {
    fontSize: 12,
  },
},
({ theme }) => ({
  color: theme.colors.textReversed,
}));

export const StyledSocialLink = styled.a({
  marginRight: 10,
  '@media (max-width: 480px)': {
    marginLeft: 5,
    marginRight: 5,
  },
});


export const StyledHipaaBadge = styled.img({
  width: 228,
  height: 147,
  '@media (max-width: 480px)': {
    width: 114,
    height: 73,
  },
});
