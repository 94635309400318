import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'emotion-theming';

import Header from '../../components/header';
import Footer from '../../components/footer';
import '../../styles/layout.css';
import { mainTheme } from '../../styles/theme';
import { MainWrapper } from './styles';

const rootQuery = graphql`
  query RootQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const mainLayout = ({ children, noFooter, paintedMobileHeader }) => (
  <ThemeProvider theme={mainTheme}>
    <StaticQuery
      query={rootQuery}
      render={() => (
        <>
          <Header paintedMobileHeader={paintedMobileHeader} />
          <MainWrapper>
            {children}
          </MainWrapper>
          {!noFooter
            && <Footer />
          }
        </>
      )}
    />
  </ThemeProvider>
);

mainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  noFooter: PropTypes.bool,
  paintedMobileHeader: PropTypes.bool,
};

export default mainLayout;
