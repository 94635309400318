import React, { Component } from 'react';
import { submitSignUpFormData } from '@utils/hubspot';
import { sendGenericSignUpThankYouEmail } from '@utils/emails';
import { isEmailValid } from '@utils/helpers';

import Button from '@components/button';
import Text, { TEXT_COLOR } from '@components/text';

import {
  Wrapper, ContentWrapper, FormContainer, StyledForm, StyledInput, StyledFormLine, ButtonWrapper,
} from './styles';

class SignUpSection extends Component {
  constructor() {
    super();
    this.state = {
      values: {
        firstname: '',
        lastname: '',
        email: '',
        dateOfBirth: '',
        country: '',
        state: '',
      },
      errors: {
        firstname: false,
        lastname: false,
        email: false,
        dateOfBirth: false,
        country: false,
        state: false,
      },
      isDataSubmitted: false,
      hasRequestError: false,
    };
  }

  handleInput = (type) => (e) => {
    const { value } = e.target;
    const { errors, hasRequestError } = this.state;
    if (hasRequestError) {
      this.setState({ hasRequestError: false });
    }
    if (errors[type]) {
      this.setState({
        errors: {
          ...errors,
          [type]: false,
        },
      });
    }
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [type]: value,
      },
    }));
  }

  handleRequestResult = (isDataSubmitted) => {
    if (isDataSubmitted) {
      this.setState({
        values: {
          firstname: '',
          lastname: '',
          email: '',
          dateOfBirth: '',
          country: '',
          state: '',
        },
        isDataSubmitted,
      });
    } else {
      this.setState({
        hasRequestError: true,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    if (this.validateValues(values)) {
      sendGenericSignUpThankYouEmail({
        firstName: values.firstname,
        email: values.email,
      });
      submitSignUpFormData({
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        dateOfBirth: values.dateOfBirth,
        country: values.country,
        state: values.state,
        onRequest: this.handleRequestResult,
      });
    }
  }

  validateValues = (values) => {
    const hasError = Object.keys(values).some((key) => {
      if (!values[key].length) {
        this.setState(({ errors }) => ({
          errors: {
            ...errors,
            [key]: true,
          },
        }));
        return true;
      }
      if (key === 'email' && !isEmailValid(values[key])) {
        this.setState(({ errors }) => ({
          errors: {
            ...errors,
            [key]: true,
          },
        }));
        return true;
      }
      return false;
    });
    return !hasError;
  }

  getButtonText = () => {
    const { hasRequestError } = this.state;
    return (hasRequestError && 'Invalid form values') || 'Send';
  }

  renderForm = () => {
    const {
      values, errors, isDataSubmitted, hasRequestError,
    } = this.state;
    return (
      <FormContainer>
        <Text fontSize={21} color={TEXT_COLOR.HIGHLIGHTED}>Sign up for Novotalk</Text>
        <StyledForm>
          <StyledFormLine>
            <StyledInput
              type="text"
              onChange={this.handleInput('firstname')}
              value={values.firstname}
              placeholder="First Name"
              disabled={isDataSubmitted}
              hasError={errors.firstname}
            />
            <StyledInput
              type="text"
              onChange={this.handleInput('lastname')}
              value={values.lastname}
              placeholder="Last Name"
              disabled={isDataSubmitted}
              hasError={errors.lastname}
            />
          </StyledFormLine>
          <StyledFormLine>
            <StyledInput
              type="email"
              onChange={this.handleInput('email')}
              value={values.email}
              placeholder="Email"
              disabled={isDataSubmitted}
              hasError={errors.email}
            />
            <StyledInput
              type="text"
              date
              onChange={this.handleInput('dateOfBirth')}
              value={values.dateOfBirth}
              placeholder="Date of Birth"
              disabled={isDataSubmitted}
              hasError={errors.dateOfBirth}
            />
          </StyledFormLine>
          <StyledFormLine>
            <StyledInput
              type="text"
              onChange={this.handleInput('country')}
              value={values.country}
              placeholder="Country"
              disabled={isDataSubmitted}
              hasError={errors.country}
            />
            <StyledInput
              type="text"
              onChange={this.handleInput('state')}
              value={values.state}
              placeholder="State"
              disabled={isDataSubmitted}
              hasError={errors.state}
            />
          </StyledFormLine>
          <ButtonWrapper center>
            <Button
              type="submit"
              onClick={this.handleSubmit}
              width={140}
              height={32}
              success={isDataSubmitted}
              alert={hasRequestError}
            >
              {this.getButtonText()}
            </Button>
          </ButtonWrapper>
        </StyledForm>
      </FormContainer>
    );
  }

  render() {
    const { isDataSubmitted } = this.state;
    if (isDataSubmitted) {
      return (
        <Wrapper>
          <ContentWrapper column>
            <Text fontSize={21} color={TEXT_COLOR.HIGHLIGHTED}>Thank you for signing up!</Text>
            <div style={{ marginTop: 10 }}>
              <Text fontSize={18} color={TEXT_COLOR.DARK_GRAY}>We'll be in touch soon</Text>
            </div>
          </ContentWrapper>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <ContentWrapper>
          {this.renderForm()}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

SignUpSection.propTypes = {
};

export default SignUpSection;
