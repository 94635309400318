import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { COURSE_TYPES } from '@utils/constants';

import Logo from '../../assets/images/logo_footer.svg';
import {
  StyledFooter, Wrapper, InfoContainer, Menu, MenuHeader, MenuItem,
  StyledLink, StyledInfoLink, StyledImg, SocialLinksContainer, StyledSocialLink,
  StyledPinterestIcon, StyledInstagramIcon, StyledTwitterIcon, StyledFacebookIcon,
  MenuItemSocialLinks, LogoWrapper, StyledHipaaBadge,
} from './styles';

class Footer extends Component {
  renderMenu = () => (
    <>
      <Menu>
        <MenuHeader>
          Solutions
        </MenuHeader>
        <ul>
          <MenuItem>
            <StyledLink to={`/programs/${COURSE_TYPES.NOVOTALK}`}>Programs</StyledLink>
          </MenuItem>
          <MenuItem>
            <StyledLink to="/methodology">Methodology</StyledLink>
          </MenuItem>
          <MenuItem>
            <StyledLink to="/pricing">Pricing</StyledLink>
          </MenuItem>
          <MenuItem>
            <StyledLink to="/faq">FAQ</StyledLink>
          </MenuItem>
        </ul>
      </Menu>
      <Menu>
        <MenuHeader>
          Company
        </MenuHeader>
        <ul>
          <MenuItem>
            <StyledLink to="/partners">Join our network of clinicians</StyledLink>
          </MenuItem>
          <MenuItem>
            <StyledLink to="/about">About us</StyledLink>
          </MenuItem>
          {/* <MenuItem>
            <StyledLink to="/blog">Blog</StyledLink>
          </MenuItem> */}
        </ul>
      </Menu>
      <Menu>
        <MenuHeader>
          Support
        </MenuHeader>
        <ul>
          <MenuItem>
            <StyledLink to="/contact">Contact us</StyledLink>
          </MenuItem>
        </ul>
      </Menu>
      <MenuItemSocialLinks>
        {this.renderSocialLinks()}
      </MenuItemSocialLinks>
    </>
  );

  renderSocialLinks = () => (
    <SocialLinksContainer>
      {/* <StyledSocialLink to="/">
        <StyledPinterestIcon />
      </StyledSocialLink> */}
      {/* <StyledSocialLink to="/">
        <StyledInstagramIcon />
      </StyledSocialLink> */}
      <StyledSocialLink target="_blank" href="https://twitter.com/novotalk">
        <StyledTwitterIcon />
      </StyledSocialLink>
      <StyledSocialLink target="_blank" href="https://www.facebook.com/novotalk1/">
        <StyledFacebookIcon />
      </StyledSocialLink>
    </SocialLinksContainer>
  );

  renderHIPAABadge = () => (
    <a href="http://compliancy-group.com/hipaa-compliance-verification" target="_blank">
      <StyledHipaaBadge
        src="https://compliancy-group.com/wp-content/uploads/2016/08/HIPAA-Compliance-Verification.png"
        alt="HIPAA Software"
      />
    </a>
  )

  render() {
    return (
      <StyledFooter>
        <Wrapper>
          <LogoWrapper>
            <StyledImg src={Logo} alt="Novotalk" onClick={() => navigate('/')} />
            {this.renderSocialLinks()}
          </LogoWrapper>
          {this.renderMenu()}
          {this.renderHIPAABadge()}
        </Wrapper>
        <InfoContainer>
          <StyledInfoLink to="/">
            Terms of Use & Privacy Policy
          </StyledInfoLink>
        </InfoContainer>
      </StyledFooter>
    );
  }
}

export default Footer;
