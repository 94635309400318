import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Link } from 'gatsby';
import { getIconsByType, ICON_TYPES } from '@utils/icons';

export const StyledHeader = styled.header({
  width: '100%',
  position: 'sticky',
  top: 0,
  zIndex: 100,
},
({ theme, paintedMobileHeader }) => ({
  height: theme.sizes.headerHeight,
  background: theme.colors.background,
  boxShadow: theme.shadows.header,
  '@media (max-width: 480px)': {
    boxShadow: 'none',
    height: theme.sizes.headerMobileHeight,
    background: paintedMobileHeader ? theme.colors.backgroundMedium : theme.colors.background,
  },
}));

export const Wrapper = styled.div({
  minWidth: 320,
  maxWidth: 1920,
  margin: '0 auto',
  padding: '0 10%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media (max-width: 480px)': {
    padding: '0 24px',
  },
},
({ theme }) => ({
  height: theme.sizes.headerHeight,
  '@media (max-width: 480px)': {
    height: theme.sizes.headerMobileHeight,
  },
}));

export const StyledNav = styled.nav({
  display: 'flex',
  alignItems: 'center',
});

export const StyledImg = styled.img({
  width: 162,
  marginRight: 36,
  cursor: 'pointer',
  '@media (max-width: 480px)': {
    display: 'none',
  },
});

export const StyledImgMobile = styled.img({
  width: 120,
  display: 'none',
  '@media (max-width: 480px)': {
    display: 'block',
  },
});

const adjustingWidthStyles = ({ title }) => {
  if (title) {
    return (
      {
        '&:before': {
          display: 'block',
          content: `"${title}"`,
          fontWeight: '600',
          height: 0,
          color: 'transparent',
          overflow: 'hidden',
          visibility: 'hidden',
          '@media (max-width: 480px)': {
            display: 'none',
          },
        },
      }
    );
  }
  return {};
};

export const MenuItemOnlyMobile = styled.li({
  display: 'none',
  '@media (max-width: 480px)': {
    display: 'block',
    fontWeight: 'bold',
    fontSize: 25,
    marginBottom: 15,
  },
});

export const Menu = styled.ul({
  display: 'flex',
  listStyle: 'none',
  '@media (max-width: 480px)': {
    minHeight: '100vh',
    maxHeight: '100vh',
    padding: 50,
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: '500',
    textAlign: 'center',
  },
},
({ theme, isMobileMenuShown }) => ({
  '@media (max-width: 480px)': {
    display: isMobileMenuShown ? 'block' : 'none',
    backgroundColor: theme.colors.textHighlighted,
  },
}));

export const MenuItem = styled.li({
  position: 'relative',
  fontSize: 15,
  marginRight: 36,
  '@media (max-width: 480px)': {
    marginRight: 0,
    marginBottom: 15,
    fontWeight: 'bold',
    fontSize: 25,
  },
},
adjustingWidthStyles);

export const SubMenu = styled.ul({
  listStyle: 'none',
  position: 'absolute',
  top: 40,
  left: -20,
  width: 250,
  padding: '0 20px',
  zIndex: 200,
  fontWeight: '400',
  '@media (max-width: 480px)': {
    width: '100%',
    position: 'initial',
    boxShadow: 'none',
  },
},
({ theme, isShown }) => ({
  display: isShown ? 'block' : 'none',
  '@media (max-width: 480px)': {
    backgroundColor: theme.colors.textHighlighted,
  },
  backgroundColor: theme.colors.background,
  boxShadow: theme.shadows.pathItem,
}));

export const SubMenuTitle = styled.div({
  marginRight: 10,
  '@media (max-width: 480px)': {
    margin: '0 auto',
    fontWeight: 'bold',
  },
},
({ theme, isSubMenuShown }) => ({
  fontWeight: isSubMenuShown ? '600' : '400',
  color: theme.colors.textHighlighted,
  '@media (max-width: 480px)': {
    color: theme.colors.textReversed,
    marginBottom: isSubMenuShown ? 15 : 0,
  },
}),
adjustingWidthStyles);

export const SubMenuHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    [SubMenuTitle]: {
      fontWeight: '600',
    },
  },
  '@media (max-width: 480px)': {
    '&:hover': {
      [SubMenuTitle]: {
        fontWeight: 'bold',
      },
    },
  },
});

export const SubMenuItem = styled.li({
  fontSize: 15,
  margin: '8px 0',
  '@media (max-width: 480px)': {
    width: 150,
    margin: '0 auto 15px auto',
    fontWeight: 'normal',
  },
});

export const DesktopButtonsContainer = styled.div({
  '@media (max-width: 480px)': {
    display: 'none',
  },
});

export const StyledLink = styled(Link)({
  cursor: 'pointer',
  '&:hover': {
    fontWeight: '600',
  },
  '@media (max-width: 480px)': {
    '&:hover': {
      fontWeight: 'inherit',
    },
  },
},
({ theme }) => ({
  color: theme.colors.textHighlighted,
  '@media (max-width: 480px)': {
    color: theme.colors.textReversed,
  },
}));

export const StyledChevronDown = styled(getIconsByType(ICON_TYPES.CHEVRON_DOWN))({
  fontSize: 22,
  marginBottom: -3,
  '@media (max-width: 480px)': {
    display: 'none',
  },
},
({ theme }) => ({
  color: theme.colors.textHighlighted,
}));

export const StyledHamburgerMenuIcon = styled(getIconsByType(ICON_TYPES.HAMBURGER_MENU))({
  display: 'none',
  fontSize: 34,
  strokeWidth: 16,
  '@media (max-width: 480px)': {
    display: 'block',
  },
},
({ theme }) => ({
  color: theme.colors.textHighlighted,
}));

export const StyledCloseIcon = styled(getIconsByType(ICON_TYPES.CLOSE), {
  shouldForwardProp: isPropValid,
})({
  display: 'none',
  fontSize: 34,
  strokeWidth: 16,
  position: 'absolute',
  top: 22,
  right: 30,
  zIndex: 510,
  cursor: 'pointer',
},
({ theme, isShown }) => ({
  color: theme.colors.textReversed,
  '@media (max-width: 480px)': {
    display: isShown ? 'block' : 'none',
  },
}));

export const StyledBackgroundImage = styled.img({
  display: 'none',
  width: '100%',
  justifySelf: 'flex-end',
  '@media (max-width: 480px)': {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
},
({ isShown }) => ({
  '@media (max-width: 480px)': {
    display: isShown ? 'block' : 'none',
  },
}));
