import {
  IoLogoPinterest, IoLogoInstagram, IoLogoTwitter, IoMdMail, IoIosCall, IoMdPin,
  IoIosArrowDown, IoMdMenu, IoMdClose,
} from 'react-icons/io';

export const ICON_TYPES = {
  PINTEREST: 'PINTEREST',
  INSTAGRAM: 'INSTAGRAM',
  TWITTER: 'TWITTER',
  MAIL: 'MAIL',
  CALL: 'CALL',
  LOCATION: 'LOCATION',
  CHEVRON_DOWN: 'CHEVRON_DOWN',
  HAMBURGER_MENU: 'HAMBURGER_MENU',
  CLOSE: 'CLOSE',
};

export const getIconsByType = (type) => {
  switch (type) {
    case ICON_TYPES.PINTEREST: return IoLogoPinterest;
    case ICON_TYPES.INSTAGRAM: return IoLogoInstagram;
    case ICON_TYPES.TWITTER: return IoLogoTwitter;
    case ICON_TYPES.MAIL: return IoMdMail;
    case ICON_TYPES.CALL: return IoIosCall;
    case ICON_TYPES.LOCATION: return IoMdPin;
    case ICON_TYPES.CHEVRON_DOWN: return IoIosArrowDown;
    case ICON_TYPES.HAMBURGER_MENU: return IoMdMenu;
    case ICON_TYPES.CLOSE: return IoMdClose;
    default:
      return null;
  }
};
