import request from 'superagent/lib/client';

export const sendGenericSignUpThankYouEmail = ({
  firstName, email,
}) => {
  const params = {
    type: 'GENERIC',
    firstName,
    email,
  };

  const req = request.post('https://api.novotalk.com/auth/novotalkIncRegistration');
  req.set('X-Novotalk-Source', 'novotalk_inc_website');
  req.type('form');
  req.send(params);
  req.then((res) => res.body, (err) => console.error(err));
  return req;
};
