import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SEO from '@components/seo';
import MainLayout from '@layouts/mainLayout';
import SignUpSection from '@components/pages/sign-up';

class ContactPage extends Component {
  getSectionContent = (section) => {
    const { data } = this.props;
    return data.cockpitcontact[section].childMarkdownRemark.html;
  }

  render() {
    return (
      <MainLayout>
        {/* TODO: add keywords */}
        <SEO title="Sign up" keywords={[]} />
        <SignUpSection />
      </MainLayout>
    );
  }
}

ContactPage.propTypes = {
  data: PropTypes.object,
};

export default ContactPage;
