import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  paddingTop: 68,
  marginBottom: 205,
  '@media (max-width: 480px)': {
    paddingTop: 30,
    marginBottom: 50,
  },
});

export const ContentWrapper = styled.div({
  margin: '0 7%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media (max-width: 480px)': {
    margin: '0 10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}, ({ column }) => ({
  display: 'flex',
  flexDirection: column ? 'column' : 'row',
}));

export const FormContainer = styled.div({
  padding: '20px 30px',
  borderRadius: 5,
  '@media (max-width: 480px)': {
    width: '100%',
    padding: '15px 25px 20px',
    marginBottom: 66,
    textAlign: 'center',
    '& h4': {
      fontSize: 21,
      marginBottom: 14,
    },
  },
},
({ theme }) => ({
  boxShadow: theme.shadows.contactForm,
}));

export const StyledForm = styled.form({
  display: 'flex',
  flexDirection: 'column',
  width: 480,
  justifyContent: 'center',
  '@media (max-width: 480px)': {
    width: 300,
    flexDirection: 'column',
    alignItems: 'center',
    '& button': {
      fontSize: 12,
    },
  },
});

const getBorderColor = (theme, hasError, disabled) => {
  if (hasError) return theme.colors.buttonAlert;
  if (disabled) return theme.colors.borderDisabled;
  return theme.colors.textInputBorder;
};

const inputStyles = [
  {
    padding: 10,
    borderRadius: 5,
    border: '1px solid',
    outline: 'none',
    fontSize: 15,
    '@media (max-width: 480px)': {
      width: '250px',
      margin: '0 0 7px 0',
      fontSize: 12,
    },
  },
  ({ theme, hasError, disabled }) => ({
    color: theme.colors.textGray,
    borderColor: getBorderColor(theme, hasError, disabled),
    backgroundColor: theme.colors.background,
    '&:focus': {
      backgroundColor: theme.colors.textInputBackground,
    },
    '&::placeholder': {
      color: disabled ? theme.colors.textDisabled : theme.colors.textLightGray,
    },
  }),
];

const getInputWidth = (type, date) => {
  if (type === 'email') return '60%';
  if (type === 'text') {
    return date ? '35%' : '45%';
  }
  return '';
};

export const StyledFormLine = styled.div({
  display: 'flex',
  width: '100%',
  '@media (max-width: 480px)': {
    flexDirection: 'column',
  },
},
({ center }) => ({
  justifyContent: center ? 'center' : 'space-between',
}));

export const ButtonWrapper = styled.div({
  display: 'flex',
  width: '100%',
  marginTop: 15,
  justifyContent: 'center',
  '@media (max-width: 480px)': {
    flexDirection: 'column',
  },
});

export const StyledInput = styled.input(
  ...inputStyles,
  {
    height: 50,
    margin: '13px 0',
    '@media (max-width: 480px)': {
      height: 32,
    },
  },
  ({ type, theme, date }) => ({
    width: getInputWidth(type, date),
    '&:before': {
      content: 'attr(placeholder) !important',
      fontFamily: 'Roboto, sans-serif',
      marginRight: '0.5em',
      color: theme.colors.textGray,
    },
  })
);
