import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const getSchema = ({
  theme, outline, height, href, success, alert,
}) => {
  if (outline) {
    return {
      border: `2px solid ${theme.colors.buttonPrimary}`,
      backgroundColor: 'transparent',
      color: theme.colors.buttonPrimary,
      lineHeight: href ? `${height - 4}px` : 'initial',
      '&:hover': {
        border: 'none',
        backgroundColor: theme.colors.buttonPrimary,
        color: theme.colors.textReversed,
        lineHeight: href ? `${height}px` : 'initial',
      },
    };
  }
  return {
    border: 'none',
    backgroundColor: (success && theme.colors.buttonSuccess)
      || (alert && theme.colors.buttonAlert) || theme.colors.buttonPrimary,
    color: theme.colors.textReversed,
    lineHeight: href ? `${height}px` : 'initial',
    transition: 'background-color 1s',
    pointerEvents: success ? 'none' : 'all',
  };
};

const StyledButton = styled.button({
  display: 'inline-block',
  outline: 'none',
  borderRadius: 5,
  cursor: 'pointer',
  textAlign: 'center',
  verticalAlign: 'middle',
},
({
  theme, width, height, fontSize, margin,
}) => ({
  width,
  height,
  fontSize,
  margin,
  '&:hover': {
    boxShadow: theme.shadows.buttonHovered,
  },
  '&:active': {
    boxShadow: 'none',
  },
}),
getSchema);

const StyledLink = StyledButton.withComponent('a');

const Button = ({ href, children, ...restProps }) => (
  href
    ? (
      <StyledLink href={href} {...restProps}>
        {children}
      </StyledLink>
    ) : (
      <StyledButton {...restProps}>
        {children}
      </StyledButton>
    )
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  margin: PropTypes.string,
  outline: PropTypes.bool,
  href: PropTypes.string,
  success: PropTypes.bool,
  alert: PropTypes.bool,
};

Button.defaultProps = {
  width: 102,
  height: 40,
  fontSize: 15,
  margin: '0',
};


export default Button;
