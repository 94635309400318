import styled from '@emotion/styled';

export const MainWrapper = styled.main({
  margin: '0 auto',
  minWidth: 320,
  maxWidth: 1920,
},
({ theme }) => ({
  minHeight: `calc(100vh - ${theme.sizes.headerHeight + theme.sizes.footerHeight + theme.sizes.footerInfoHeight}px)`,
}));
