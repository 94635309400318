import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Logo from '@assets/images/logo_header.svg';
import LogoMobile from '@assets/images/logo_header_small.svg';
import BackgroundImg from '@assets/images/menu_mobile.svg';
import { COURSE_TYPES } from '@utils/constants';
import Button from '../button';
import {
  StyledHeader, Wrapper, StyledNav, StyledImg, Menu, MenuItem, SubMenuHeader, SubMenuTitle, SubMenu, SubMenuItem, StyledLink,
  StyledChevronDown, DesktopButtonsContainer, StyledHamburgerMenuIcon, StyledCloseIcon, MenuItemOnlyMobile, StyledBackgroundImage,
  StyledImgMobile,
} from './styles';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      isSubMenuShown: false,
      isMobileMenuShown: false,
    };
  }

  showSubMenu = () => {
    const { isSubMenuShown } = this.state;
    if (!isSubMenuShown) {
      this.setState({
        isSubMenuShown: true,
      });
      // eslint-disable-next-line no-undef
      document.body.addEventListener('click', this.closeSubMenu);
    } else {
      this.closeSubMenu();
    }
  }

  showMobileMenu = () => {
    this.setState({
      isMobileMenuShown: true,
    });
  }

  closeSubMenu = (e) => {
    e.stopPropagation();
    this.setState({
      isSubMenuShown: false,
    });
    // eslint-disable-next-line no-undef
    document.body.removeEventListener('click', this.closeSubMenu);
  }

  closeMobileMenu = () => {
    this.setState({
      isMobileMenuShown: false,
    });
  }

  getMenu = () => {
    const { isSubMenuShown, isMobileMenuShown } = this.state;
    return (
      <Menu isMobileMenuShown={isMobileMenuShown}>
        <StyledCloseIcon isShown={isMobileMenuShown} onClick={this.closeMobileMenu} />
        <MenuItemOnlyMobile>
          <StyledLink to="/">Home</StyledLink>
        </MenuItemOnlyMobile>
        <MenuItem>
          <SubMenuHeader onClick={this.showSubMenu}>
            <SubMenuTitle title="Programs" isSubMenuShown={isSubMenuShown}>
              Programs
            </SubMenuTitle>
            <StyledChevronDown />
          </SubMenuHeader>
          <SubMenu isShown={isSubMenuShown}>
            <SubMenuItem>
              <StyledLink to={`/programs/${COURSE_TYPES.NOVOTALK}/index.html`}>{`${COURSE_TYPES.NOVOTALK}`}</StyledLink>
            </SubMenuItem>
            {/* <SubMenuItem>
              <StyledLink to={`/programs/${COURSE_TYPES.JUNIOR}`}>{`${COURSE_TYPES.JUNIOR}`}</StyledLink>
            </SubMenuItem>
            <SubMenuItem>
              <StyledLink to={`/programs/${COURSE_TYPES.ACCELERATOR}`}>{`${COURSE_TYPES.ACCELERATOR}`}</StyledLink>
            </SubMenuItem> */}
          </SubMenu>
        </MenuItem>
        <MenuItem title="Methodology">
          <StyledLink to="/methodology">Methodology</StyledLink>
        </MenuItem>
        <MenuItem title="Pricing">
          <StyledLink to="/pricing">Pricing</StyledLink>
        </MenuItem>
        <MenuItem title="FAQ">
          <StyledLink to="/faq">FAQ</StyledLink>
        </MenuItem>
        <MenuItem title="Contact us">
          <StyledLink to="/contact">Contact us</StyledLink>
        </MenuItem>
        <MenuItemOnlyMobile>
          <StyledLink to="/about">About us</StyledLink>
        </MenuItemOnlyMobile>
        <MenuItemOnlyMobile>
          <StyledLink to="/partners">Join our network of clinicians</StyledLink>
        </MenuItemOnlyMobile>
        <StyledBackgroundImage isShown={isMobileMenuShown} src={BackgroundImg} alt="" />
      </Menu>
    );
  };

  render() {
    const { paintedMobileHeader } = this.props;
    return (
      <StyledHeader paintedMobileHeader={paintedMobileHeader}>
        <Wrapper>
          <StyledNav>
            <StyledImg src={Logo} alt="Novotalk" onClick={() => navigate('/')} />
            <StyledImgMobile src={LogoMobile} alt="Novotalk" onClick={() => navigate('/')} />
            {this.getMenu()}
          </StyledNav>
          <DesktopButtonsContainer>
            <Button href="/sign-up/index.html">
              Sign up
            </Button>
          </DesktopButtonsContainer>
          <StyledHamburgerMenuIcon onClick={this.showMobileMenu} />
        </Wrapper>
      </StyledHeader>
    );
  }
}

Header.propTypes = {
  paintedMobileHeader: PropTypes.bool,
};

export default Header;
