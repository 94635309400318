export const mainTheme = {
  colors: {
    background: '#FFF',
    backgroundMedium: '#FCFCFC',
    backgroundBlue: '#D6EEF4',
    textLightGray: '#909090',
    textGray: '#656565',
    textDarkGray: '#484848',
    textReversed: '#FFF',
    textHighlighted: '#00B7E5',
    buttonPrimary: '#F08920',
    buttonSuccess: '#99E2B3',
    buttonAlert: '#F42237',
    footerBackground: '#656565',
    storiesBackground: 'rgba(155, 214, 229, 0.4)',
    socialLink: '#D1DBE3',
    tableBorder: '#E0E3E5',
    triggerBackground: '#D1DBE3',
    trialLink: '#9BD6E5',
    textInputBackground: '#F4F4F4',
    textInputBorder: '#9BD6E5',
    textDisabled: '#B5B5B5',
    borderDisabled: '#DCE1E2',

  },
  shadows: {
    header: '0 25px 23px rgba(155, 214, 229, 0.07)',
    successStories: '0px 0px 20px 8px #E0E3E5',
    successStoriesUser: '0 20px 30px rgba(0, 0, 0, 0.16)',
    pathItem: '0 3px 8px rgba(0, 0, 0, 0.1)',
    pathItemHovered: '0 10px 20px rgba(0, 0, 0, 0.18)',
    buttonHovered: '0 10px 20px rgba(0, 0, 0, 0.16)',
    platformOverviewAccordion: '0 3px 8px rgba(0, 0, 0, 0.06)',
    comparisonTableNovotalk: '0 10px 20px rgba(0, 0, 0, 0.18)',
    comparisonTableOther: '0 3px 8px rgba(0, 0, 0, 0.1)',
    syllabusListItem: '0 3px 14px rgba(0, 0, 0, 0.08)',
    syllabusListItemOpened: '0 3px 14px rgba(0, 0, 0, 0.16)',
    triggerButton: '0 5px 10px rgba(0, 0, 0, 0.3)',
    contactForm: '0 40px 80px rgba(0, 0, 0, 0.1)',
    heroMobile: '0 2px 4px rgba(0, 0, 0, 0.05)',
    triggerMobile: '0 3px 8px rgba(0, 0, 0, 0.08)',
    emily: '0px 30px 30px 0px rgba(0, 0, 0, 0.16)',
  },
  gradients: {
    successStories: 'linear-gradient(185deg, rgba(155, 214, 229, 0.4), #FFF 90%);',
  },
  sizes: {
    headerHeight: 100,
    footerHeight: 296,
    footerInfoHeight: 40,
    headerMobileHeight: 53,
    footerMobileHeight: 187,
    footerMobileInfoHeight: 22,
  },
};
